import React, { useEffect, useContext, useState } from "react";
import { Link, graphql } from "gatsby";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import ToolLinks from "../components/ToolLinks/ToolLinks";
import Footer from "../components/Footer/Footer";

import { GlobalDispatchContext } from "../context/GlobalContextProvider";

const ITEMS_PER_PAGE = 20; // Number of items to display per page
const MAX_VISIBLE_PAGES = 5; // Maximum number of visible page numbers

const TagPage = ({ data }) => {
  const dispatch = useContext(GlobalDispatchContext);
  const tag = data.tag;
  const allTag = tag.tools;

  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const visibleTages = allTag.slice(startIndex, endIndex);

  const totalPages = Math.ceil(allTag.length / ITEMS_PER_PAGE);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPaginationButtons = () => {
    const pageButtons = [];

    // Calculate which page numbers to display
    let startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      1
    );
    let endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages);

    if (endPage - startPage < MAX_VISIBLE_PAGES - 1) {
      startPage = Math.max(endPage - MAX_VISIBLE_PAGES + 1, 1);
    }

    // Add "First" button
    if (startPage > 1) {
      pageButtons.push(
        <button key={1} onClick={() => handlePageChange(1)}>
          1
        </button>
      );

      // Add ellipsis if necessary
      if (startPage > 2) {
        pageButtons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Add page numbers
    for (let page = startPage; page <= endPage; page++) {
      pageButtons.push(
        <button
          key={page}
          className={currentPage === page ? "active" : ""}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      );
    }

    // Add "Last" button
    if (endPage < totalPages) {
      // Add ellipsis if necessary
      if (endPage < totalPages - 1) {
        pageButtons.push(<span key="ellipsis-end">...</span>);
      }

      pageButtons.push(
        <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <Page>
      <TopBar showSearch={true} />
      {tag.title && (
        <>
          <Section
            style={{
              color: "#fff",
              lineHeight: "27px",
            }}
          >
            <SectionHeading>{tag.title}</SectionHeading>
            {tag.description && (
              <div className="tool-description">{tag.description}</div>
            )}
            <ToolLinks tools={visibleTages} />
          </Section>
        </>
      )}
      <div className="pagination">{renderPaginationButtons()}</div>
      <Footer />
    </Page>
  );
};

export default TagPage;

export const Head = ({ data }) => {
  const tag = data.tag;
  const location = useLocation();

  return (
    <SEO
      index
      follow
      title={
        tag.toolTitle
          ? tag.toolTitle.replace('"', "")
          : `${tag.title} | AI Product Reviews`
      }
      description={tag.description}
      pathname={location.pathname}
    />
  );
};

export const query = graphql`
  query TagPageQuery($id: String!) {
    tag(id: { eq: $id }) {
      id
      title
      tools {
        id
        title
        screenshot
        summaryDescription
        slug
      }
    }
  }
`;
